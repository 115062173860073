.quotecard-wrapper {
    margin: 0 10px 0 10px !important;
    padding: 0 !important;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    position: relative;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    unicode-bidi: isolate;
    line-height: 1.5;
    word-wrap: break-word;
}

.quotecard-wrapper .quotecard-img-wrapper {
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
    max-width: 100% !important;
    margin: 0 auto !important;
    padding: 0 !important;
    text-align: start !important;
    height: auto !important;
    width: calc(100% - 0px) !important;
    min-width: 25px !important;
    line-height: 1.5;
    unicode-bidi: isolate;



}

.quotecard-wrapper .quotecard-img-wrapper .quotecard-image {
        height: auto;
        width: auto;
        vertical-align: top;
        width: 100%;
        max-width: 100%;
        aspect-ratio: auto 1920 / 2560;
        display: inline-block;
        box-sizing: border-box;
        overflow-clip-margin: content-box;
        overflow: clip;
        line-height: 1.5;
        text-align: start !important;
}


.quotecard-wrapper .quotecard-header-wrapper {
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    width: calc(100% - 0px) !important;
    position: relative !important;
    height: auto !important;
    max-width: 100% !important;
    margin: 8px auto !important;
    padding: 2px 0 !important;
    min-width: 25px !important;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    display: block;
    unicode-bidi: isolate;

    h2 {
        text-decoration: inherit;
        font-size: 35px;
        line-height: initial;
        color: inherit;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        display: block;
        unicode-bidi: isolate;
        text-align: left;
    }
}

.quotecard-wrapper .quotecard-description-wrapper {
    margin: 8px 0 !important;
    padding: 2px 60px 2px 0 !important;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    max-width: 100%;
    text-align: left;
    display: block;
    unicode-bidi: isolate;
    box-sizing: border-box;

    p {
        line-height: 1.5;
        font-weight: 400;
        font-family: Roboto;
        direction: ltr;
        color: #000;
        font-style: normal;
        text-decoration: none;
        margin-top: 0;
        margin-bottom: 0;
        display: block;
        margin: 0;
        unicode-bidi: isolate;
        font-size: 18px;
        text-align: left;
    }
}

.quotecard-wrapper .quotecard-description-wrapper p span {
    display: unset;
    line-height: 1.5;
    font-weight: 400;
    font-family: Roboto;
    direction: ltr;
    color: #000;
    font-style: normal;
    font-size: 18px;
    text-align: left;
    word-wrap: break-word;
}


.quotecard-wrapper:nth-child(2) .quotecard-button {
    margin-top: 2.3rem !important;
}


.quotecard-wrapper .quotecard-button:focus {
    outline: 2px solid black;
    outline-offset: 5px;
}

.quotecard-wrapper .quotecard-button {
    display: block !important;
    height: 60px !important;
    margin: 10px 0 !important;
    padding: 0 !important;
    text-align: center !important;
    max-width: 100% !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    width: calc(100% - 0px) !important;
    position: relative !important;
    clear: both;
    text-decoration: none;
    border-style: solid;
    white-space: nowrap;
    transition: background-color .2s ease, border-color .2s ease;
    line-height: 22px;
    cursor: pointer;
    box-shadow: none;
    background-image: none;
    outline: 0;
    box-sizing: border-box;
    border: 0 solid #333;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 400;
    word-wrap: break-word;
    background-color: rgb(247, 249, 52);
    border-radius: 50px;
    border: 1px solid black;

    &::after {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        width: 0;
        margin-right: -.25em;
        box-sizing: border-box;
    }

}

.quotecard-wrapper .quotecard-button .quotecard-button-text {
    padding: 10px 0;
    transition: color .2s ease;
    line-height: normal;
    white-space: normal;
    display: inline-block;
    vertical-align: middle;
    max-width: 98%;
    box-sizing: border-box;
    color: black;
    font-size: 15px;
    font-weight: 400;
    font-family: Roboto;
}


.quotecard-wrapper .quotecard-button:hover {
    background-color: white;
}

@media screen and (max-width: 785px) {
    .quotecards-container {
        flex-direction: column;
        gap: 20px
    }

    .quotecard-wrapper {
        width: 100% !important;
        margin: 0 !important;
    }
}
.content-columns-wrapper {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    unicode-bidi: isolate;
    box-sizing: border-box;
}

.content-columns-wrapper .form-background {
    background-color: black !important;
}

.captcha-wrapper {
    display: flex;
    position: relative;
    z-index: 99999;
    margin-left: 1rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

.content-columns-wrapper .content-column {
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 10px !important;
    padding-right: 0 !important;
    position: relative;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    line-height: 1.5;
    box-sizing: border-box;

    h2, h2 span {
        font-size: 35px;
        color: #000;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
        margin: 0;
    }

    h3, h3 span {
        font-size: 25px;
        color: #000;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
        margin: 0;
    }
} 






@media screen and (min-width: 1200px) {
    .content-columns-wrapper {
        max-width: 1200px;
    }
}



@media screen and (max-width: 785px) {
    .content-columns-wrapper .content-column .section-content:nth-child(6) {
        ul {
            flex-direction: column;
        }
    }
}
:root {
  font-family: Roboto !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.page-content-container {
  max-width: none;
  background-color: transparent;
  
  &::before {
    content: "";
    display: table;
    box-sizing: border-box;
  }

  &::after {
    clear: both;
    content: ' ';
    display: table;
    text-align: center !important;
    box-sizing: border-box;
  }
}


.page-wrapper {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 18px;
  color: #000;
  font-family: Roboto;
  font-weight: 400;
  line-height: initial;
}

.page-wrapper .content-wrapper .page-content .page-content-wrapper {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  color: #000;
  font-family: Roboto;
  font-weight: 400;
  font-size: 18px;
  position: relative;
  width: 100%;
  float: left;
  line-height: initial;
  word-wrap: break-word;
}

.section-content-list {
    padding: 0;
    margin: 0;
    display: block;
    list-style-type: disc;

    li {
        line-height: 1.6;
        color: inherit;
        font-weight: 400;
        font-family: Roboto;
        font-style: normal;
        text-decoration: none;
        direction: ltr;
        display: list-item;
        text-align: -webkit-match-parent;
        unicode-bidi: isolate;
        font-size: 18px;
        margin: 0 1em;
        margin-inline-end: 0;
        margin-inline-start: 2em;
    }
}
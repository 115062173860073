.mobile-navbar {
    background-attachment: scroll;
    border-color: rgb(51, 51, 51);
    background-color: white;
    border-style: solid;
    border-width: 0px;
    border-image-outset: 0;
    border-image-repeat: stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    box-sizing: border-box;
    display: block;
    font-family: Roboto;
    font-size: 15px;
    height: 79px;
    left: 0;
    line-height: normal;
    overflow-wrap: break-word;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 2;
}

.navbar-placeholder {
    height: 79px; /* Same as navbar height */
    width: 100%;
}

.mobile-navbar .mobile-navbar-wrapper {
    background-color: rgb(0, 0, 0, 0);
    line-height: normal;
    min-height: 43px;
    height: 79px;
    z-index: 2;
    box-shadow:  rgba(0, 0, 0, 0.25) 0px 3px 11px 0px;
    box-sizing: border-box;
    display: block;
}

.mobile-navbar .mobile-navbar-wrapper .mobile-navbar-container {
    max-width: 960px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.mobile-navbar .mobile-navbar-wrapper .mobile-navbar-container .mobile-navbar-items {
    display: flex;
    flex-wrap: nowrap;
    max-width: 100%;
    width: 100%;
    height: 59px;
    line-height: normal;
    position: relative;
    text-align: center;
    justify-content: space-between;
}

.mobile-navbar .mobile-navbar-wrapper .mobile-navbar-container .mobile-navbar-items i {
    color: black;
}

.mobile-navbar .mobile-navbar-wrapper .mobile-navbar-container .mobile-navbar-items .mobile-navbar-hamburger-toggle {
    height: 100%;
    font-size: 2rem;
    align-items: center;
    display: flex;
    min-height: 55px;
    font-weight: 400;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    line-height: normal;
    vertical-align: top;
    z-index: 1001;
}

.hamburger-container {
    height: 100%;
    font-size: 2rem;
    align-items: center;
    display: flex;
    min-height: 55px;
    font-weight: 400;
    text-align: center;
    padding-left: 15px;
    position: relative;
    line-height: normal;
    vertical-align: top;
    z-index: 1001;
    
  }
  
  .hamburger-toggle {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    width: 35px;
    height: 28px;
    transition: transform 0.35s ease-out;
  }
  
  .hamburger-toggle .line {
    width: 100%;
    height: 4px;
    background-color: #333;
    transition: all 0.35s ease;
  }
  
  .hamburger-toggle .line.middle {
    opacity: 1;
  }
  
  .hamburger-toggle .line.top.open {
    transform: rotate(45deg) translate(9px, 9px);
  }
  
  .hamburger-toggle .line.middle.open {
    opacity: 0;
  }
  
  .hamburger-toggle .line.bottom.open {
    transform: rotate(-45deg) translate(8px, -8px);
  }
  
  /* Optional hover effect */
  .hamburger-toggle:hover .line {
    background-color: #666;
  }
  



.mobile-navbar .mobile-navbar-wrapper .mobile-navbar-container .mobile-navbar-items .mobile-navbar-logo-wrapper {
    display: flex;
    height: 59px;
    float: none;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    text-align: center;
    width: 259px;
    vertical-align: top;
}

.mobile-navbar .mobile-navbar-wrapper .mobile-navbar-container .mobile-navbar-items .mobile-navbar-logo-wrapper .mobile-navbar-logo {
    line-height: 22.5px;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 0.5px;
    margin-left: 0.5px;
    max-width: 100%;
    min-width: 0;
    padding: 0;
    position: relative;
    text-align: center;
    top: 0px;
    width: 116px;

    a {
        width: auto;
        vertical-align: baseline;
        height: auto;
        display: inline;

        img {
            aspect-ratio: auto 473 / 238;
            background-attachment: scroll;
            border-color: rgb(70, 57, 57);
            border-style: solid;
            border-width: 0px;
            border-image-outset: 0;
            border-image-repeat: stretch;
            border-image-slice: 100%;
            border-image-source: none;
            border-image-width: 1;
            box-sizing: border-box;
            display: inline-block;
            text-align: center;
            width: 116px;
            height: 59px;
            line-height: 22.5px;
            max-width: 100%;
        }
    }
}

.mobile-navbar .mobile-navbar-wrapper .mobile-navbar-container .mobile-navbar-items .mobile-navbar-contact-wrapper {
    align-items: center;
    display: flex;
    min-height: 55px;
    font-weight: 400;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 15px;
    position: relative;
    line-height: normal;
    vertical-align: top;
    font-size: 1.6rem;
}

/* Dimming overlay when the mobile menu is open */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 998; /* Lower than the navmenu, higher than other content */
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease; /* Smooth transition */
  }
  
  /* When the menu is open, show the overlay */
  .overlay.visible {
    opacity: 1;
    pointer-events: all; /* Allow clicks */
  }
  
  /* Adjust the mobile-navmenu to stay above the overlay */
  .mobile-navmenu-wrapper.open {
    position: fixed;
    top: 0;
    left: 0;
    width: 80vw;
    height: 100vh;
    z-index: 999; /* Higher than the overlay */
    background-color: white;
    transition: transform 0.3s ease;
    transform: translateX(0); /* Slide in from the left */
    overflow-y: visible; /* Enable scrolling */
    overflow-x: visible;
  }
  
  

.mobile-navmenu-wrapper.closed {
    position: fixed;
    top: 0;
    left: 0;
    width: 80vw;
    height: 100vh;
    z-index: 999;
    background-color: white;
    transition: transform 0.3s ease;
    transform: translateX(-100%); /* Initially hidden off-screen */
    overflow: hidden;
}



.mobile-navbar .mobile-navmenu-wrapper .mobile-navmenu-container {
    flex-basis: auto;
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0;
    padding-top: 80px;
    padding-right: 20px;
    padding-left: 7px;
    padding-bottom: 15px;
    position: relative;
    text-align: center;
    visibility: visible;
}

.mobile-navbar .mobile-navmenu-wrapper .mobile-navmenu-container .mobile-navmenu {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
    visibility: visible;
    position: relative;
    text-align: center;
    overflow-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
}

.mobile-navbar .mobile-navmenu-wrapper .mobile-navmenu-container .mobile-navmenu .mobile-navmenu-items {
    display: block;
    float: none;
    visibility: inherit;
    position: relative;
    text-align: center;
    line-height: normal;
    font-size: 15px;
    font-family: Roboto;
    padding-left: 15px !important;
    max-width: 100%;
    
}

.mobile-navbar .mobile-navmenu-wrapper .mobile-navmenu-container .mobile-navmenu .mobile-navmenu-items .mobile-socials-wrapper {
    height: 39px;
    left: 0;
    line-height: 22.5px;
    float: none;
    margin: 0;
    margin-right: 13px;
    max-width: 100%;
    min-width: 25px;
    display: block;
    position: relative;
    text-align: left;
    top: 0;

    .socials-row {
        display: flex;
        justify-content: start !important;
    }
}

.mobile-navbar .mobile-navmenu-wrapper .mobile-navmenu-container .mobile-navmenu .mobile-navmenu-items .mobile-email-wrapper {
    margin-top: 8px;
    margin-bottom: 8px;
    display: block;
    line-height: 22.5px;
    width: 100%;
    padding-bottom: 2px;
    padding-top: 2px;
    text-align: left;
    word-wrap: break-word;
    
    p {
        font-size: 15px;
        font-family: Roboto;
        font-weight: 400;
        width: auto;
        margin: 0;
        max-width: 100%;

        a {
            font-weight: bold;
            text-decoration: none;
            color: inherit;
        }
    }
}

.mobile-navbar .mobile-navmenu-wrapper .mobile-navmenu-container .mobile-navmenu .mobile-navmenu-items .mobile-navmenu-navlinks-wrapper .navlink {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.mobile-navbar .mobile-navmenu-wrapper .mobile-navmenu-container .mobile-navmenu .mobile-navmenu-items .mobile-navmenu-navlinks-wrapper {
    align-items: stretch;
    display: flex;
    font-size: 15px;
    font-family: Roboto;
    font-weight: 400;
    justify-content: flex-start;
    line-height: 22.5px;
    max-width: 100%;
    margin-top: 10px;
    min-width: 135px;
    overflow-wrap: break-word;
    overflow-y: visible;
    overflow-x: visible;
    padding: 0;
    text-align: start;
    visibility: inherit;
    width: 258px;
}

.mobile-navbar .mobile-navmenu-wrapper .mobile-navmenu-container .mobile-navmenu .mobile-navmenu-items .mobile-navmenu-navlinks-wrapper .mobile-navmenu-navlinks {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 16px;
    font-family: Roboto;
    line-height: 24px;
    margin: 0;
    padding: 0;
    text-align: start;
    vertical-align: top;
    visibility: inherit;
    width: 258px;


    li {
        height: 52px;
        justify-content: flex-start;
        display: flex;
        text-align: left;
        position: relative;
        vertical-align: top;

        a{
            padding-top: 10px;
            padding-bottom: 10px;


            span {
                margin: 0;
            }
        }
    }
}

.mobile-contact-bar-wrapper {
    bottom: 0px;
    box-sizing: border-box;
    display: block;
    height: 75px;
    left: 0px;
    opacity: 1;
    padding-bottom: 12px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 13px;
    position: fixed;
    z-index: 1001;
}

.mobile-contact-bar-wrapper .mobile-contact-bar {
    display: flex;
    height: 50px;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    text-align: left;
    width: fit-content;
    flex-direction: row;
    gap: 10px !important;
}

.mobile-contact-bar-wrapper .mobile-contact-bar .contact-menu-toggle:first-child button {
    margin-left: 19px;
}

.mobile-contact-bar-wrapper .mobile-contact-bar .contact-menu-toggle {
    width: 74px;
    position: relative;
    

    button, a {
        border-radius: 50px;
        background-color: rgb(247, 249, 52);
        color: black;
        height: 50px;
        width: 50px;
        border: none;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        display: flex;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: rgb(251, 253, 96);
        }

        &:focus {
            outline-offset: 5px;
        }
    }
}


.mobile-contact-bar-wrapper .mobile-contact-bar .mobile-contact-bar-modal-toggle {
    border-radius: 50px;
    background-color: rgb(247, 249, 52);
    color: black;
    line-height: 50px;
    display: block;
    margin-left: 143px;
    margin-bottom: 0;
    margin-right: 19px;
    padding: 0;
    text-align: center;
    width: 218px;
}
.top-rated-section-wrapper {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    border: 0 solid #333;
    unicode-bidi: isolate;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: initial;
    box-sizing: border-box;
}

.top-rated-section-wrapper .top-rated-content-wrapper {
    margin: 0;
    padding: 0;
}


.top-rated-section-wrapper .top-rated-content-wrapper .top-rated-header {
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    max-width: 100%;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    display: block;
    unicode-bidi: isolate;
    word-wrap: break-word;
    box-sizing: border-box;

    h2, h2 span {
        margin: 0;
        font-size: 35px;
        line-height: initial;
        color: inherit;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
    }
}


.top-rated-section-wrapper .top-rated-content-wrapper .top-rated-content {
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    max-width: 100%;
    line-height: 1.5;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;

    a {
        display: unset;
        font-weight: bold;
        color: inherit;
        text-decoration: none;
    }

    p, p span {
        line-height: 1.5;
        font-weight: 400;
        font-family: Roboto;
        font-style: normal;
        text-decoration: none;
        margin: 0;
        color: inherit;
        direction: ltr;
        display: block;
        unicode-bidi: isolate;
        text-align: left;
        box-sizing: border-box;
    }
}


@media screen and (min-width: 1200px) {
    .top-rated-section-wrapper {
        max-width: 1200px;
    }
}
.cta-section-wrapper {
    max-width: 960px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    unicode-bidi: isolate;
    text-align: start !important;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: initial;
}

.cta-section-wrapper .cta-content-wrapper {
    margin: 0;
    padding: 0;
}

.cta-section-wrapper .cta-content-wrapper .cta-content {
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    width: calc(100% - 121px) !important;
    position: relative !important;
    height: auto !important;
    max-width: 958px !important;
    margin: 8px auto !important;
    padding: 2px 0 !important;
    min-width: 25px !important;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.5;

    h2, h2 span {
        text-decoration: inherit;
        font-size: 35px;
        line-height: initial;
        color: #000;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
        text-align: center !important;
        unicode-bidi: isolate;
        display: block;
        margin: 0;
        color: rgb(255, 255, 255);
    }
}
.seamless-gutters-section-wrapper {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    border: 0 solid #333;
    unicode-bidi: isolate;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: initial;
    word-wrap: break-word;
}

.seamless-gutters-section-wrapper .seamless-gutters-content-wrapper {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 400;
    color: #000;
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    line-height: initial;
    unicode-bidi: isolate;
    box-sizing: border-box;
    border: 0 solid #333;
}

.seamless-gutters-section-wrapper .seamless-gutters-content-wrapper .seamless-gutters-section-header {
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.5;
    max-width: 100%;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    display: block;
    unicode-bidi: isolate;

    h2 {
        text-decoration: inherit;
        font-size: 35px;
        line-height: initial;
        color: #fff;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
        margin: 0;
        unicode-bidi: isolate;
        display: block;
        text-align: left;
        word-wrap: break-word;
    }
}


.seamless-gutters-section-wrapper .seamless-gutters-content-wrapper .seamless-gutters-section-content {
    color: #fff;
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;

    p{
        line-height: 1.5;
        font-weight: 400;
        font-family: Roboto;
        direction: ltr;
        color: #fff;
        font-style: normal;
        text-decoration: none;

        border: 0 solid #333;
        box-sizing: border-box;
        display: block;
        margin: 0;
        unicode-bidi: isolate;
        font-size: 18px;
    }
}


@media screen and (min-width: 1200px) {
    .seamless-gutters-section-wrapper {
        max-width: 1200px;
    }
}
.page-wrapper .content-wrapper .page-content .page-title-wrapper .page-subtitle {
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    max-width: 100%;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    display: block;
    unicode-bidi: isolate;

    p {
        margin: 0
    }

    p a {
        display: unset;
        font-weight: bold;
        font-style: italic;
        color: inherit
    }
}
.video-row-wrapper {
    padding: 0 !important;
    width: 100%;
    margin-top: 20px !important;
    position: relative;
}

.video-wrapper {
    width: 50%; /* Adjusted to make the video smaller */
    display: inline-block;
    position: relative;
    vertical-align: top;
}

.video-container {
    width: 100%;

    position: relative;
    padding-top: 56.25%; /* Keep the aspect ratio intact */
}


.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

.gallery-row-wrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin: 0;
    line-height: 1.5;
    max-width: none;
    width: 100%;
}

.gallery-row-wrapper .gallery-row {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    line-height: 1.5;
    unicode-bidi: isolate;
    margin-top: 10px;
}

.page-title-wrapper .gallery-row-wrapper:first-child .gallery-row {
    margin-top: 0 !important;
}


.gallery-row-wrapper .gallery-row .gallery-img-wrapper {
    margin-left: 10px !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 10px !important;
    padding-right: 0 !important;
    border-color: rgba(0, 0, 0, 1) !important;
    border-width: 0 !important;
    border-style: solid !important;
    position: relative;
    width: 25%;
    display: inline-block;
    vertical-align: top;
    
}



.page-wrapper .page-content-container .content-wrapper .page-content .page-content-wrapper .page-title-wrapper .gallery-row-wrapper:nth-child(5) .gallery-row .gallery-img-wrapper .gallery-image {
    max-height: none !important;
}




.gallery-row-wrapper .gallery-row .gallery-img-wrapper .gallery-image {
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    width: calc(100% - 0px) !important;
    position: relative !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    max-width: 100% !important;
    padding-right: 0 !important;
    min-width: 25px !important;
    text-align: start !important;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    overflow: hidden;
    max-height: 210px;

    img {
        height: auto;
        width: 100%;
        max-width: 100%;
        display: inline-block;
        vertical-align: top;
        border: 0;
        box-sizing: border-box;
        text-align: start !important;
        aspect-ratio: auto 555 / 416;
        background-repeat: no-repeat !important;
        background-size: contain !important;
    }
}

.gallery-row-wrapper .gallery-row .gallery-img-wrapper:first-child {
    margin-left: 0 !important;
}

.gallery-row-wrapper .gallery-row .gallery-img-wrapper:last-child {
    margin-right: 0 !important;
}


@media screen and (min-width: 1200px) {
    .gallery-row-wrapper .gallery-row {
        max-width: 1200px;
    }
}

@media screen and (max-width: 785px) {

    .gallery-row-wrapper .gallery-row {
        flex-direction: column; /* Stack items vertically */
        align-items: center !important;
        gap: 10px;

    }

    .gallery-row-wrapper .gallery-row .gallery-img-wrapper {
        width: 100% !important; /* Full width */
        margin: 0 !important; /* Remove margins */
        aspect-ratio: unset !important;
        height: fit-content !important;
        max-height: unset !important;
      }
    
      /* Ensure the images inside take full width */
      .gallery-row-wrapper .gallery-row .gallery-img-wrapper .gallery-image, .gallery-image img {
        width: 100% !important; 
        height: auto !important; /* Let height adjust based on width */
        margin: 0; /* Remove margins */
        aspect-ratio: unset !important;
        height: fit-content !important;
        max-height: 100% !important;
      }

      .video-wrapper {
        width: 100%;
      }
}


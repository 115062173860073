.quote-wrapper {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    unicode-bidi: isolate;
    unicode-bidi: isolate;
    box-sizing: border-box;
    line-height: initial;
    word-wrap: break-word;

    &::after {
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
    }
}

.quote-wrapper .quote-content-wrapper {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    line-height: initial;
    word-wrap: break-word;
}

.quote-wrapper .quote-content-wrapper .quote-section-title {
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    box-sizing: border-box;

    h1 {
        text-decoration: inherit;
        font-size: 45px;
        line-height: initial;
        color: inherit;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        box-sizing: border-box;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
        display: block;
        text-align: left;
        padding: 0;
        margin: 0;
    }
}

.quote-wrapper .quote-content-wrapper .quote-section-content-wrapper {
    padding: 0 !important;
    line-height: 1.5;
    margin: 0;
    width: 100%;
    max-width: none;
    position: relative;
    border: 0 solid #333;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;

    &::before {
        content: ' ';
        display: table;
        box-sizing: border-box;
        line-height: 1.5;
    }

    &::after {
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
        line-height: 1.5;
    }
}

.quote-wrapper .quote-content-wrapper .quote-section-content-wrapper .quotecards-container {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    unicode-bidi: isolate;
    line-height: 1.5;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    word-wrap: break-word;
    box-sizing: border-box;
}

.quote-wrapper .quote-content-wrapper .quote-section-content-wrapper .quotecards-container .quotecard-wrapper:first-child {
    margin-left: 0 !important;
}


@media screen and (min-width: 1200px) {
    .quote-wrapper {
        max-width: 1200px;
    }

    .quote-section-content-wrapper .quotecard-wrapper {
        max-width: 1200px;
    }

    .quote-wrapper .quote-content-wrapper .quote-section-content-wrapper .quotecards-container {
        max-width: 1200px;
    }
}


.our-history-content-wrapper {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    border: 0 solid #333;
    box-sizing: border-box;
    unicode-bidi: isolate;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: initial;
    word-wrap: break-word;
}

.our-history-content-wrapper .history-column:first-child {
    margin-left: 0;
}

.our-history-content-wrapper .history-column {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0;
    margin-top: 0;
    padding: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    position: relative;
    width: 50%;
    vertical-align: top;
    float: left;
    box-sizing: border-box;
}

.our-history-content-wrapper .history-column .history-column-header {
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.5;
    max-width: 100%;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    display: block;
    unicode-bidi: isolate;

    h2{
        text-decoration: inherit;
        font-size: 35px;
        line-height: initial;
        color: inherit;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
        margin: 0;
        display: block;
        unicode-bidi: isolate;
        word-wrap: break-word;
    }
}

.our-history-content-wrapper .history-column .history-column-content {
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.5;
    max-width: 100%;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    display: block;
    unicode-bidi: isolate;
    word-wrap: break-word;
}


.our-history-content-wrapper .history-column .history-column-content p {
    line-height: 1.5;
    font-weight: 400;
    font-family: Roboto;
    direction: ltr;
    color: #000;
    font-style: normal;
    text-decoration: none;
    display: block;
    unicode-bidi: isolate;
    font-size: 18px;
    text-align: left;
    margin: 0;
    word-wrap: break-word;
}


.our-history-content-wrapper .history-column .history-img-wrapper {
    width: 100% !important;
    display: inline-block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    unicode-bidi: isolate;

    img {
        height: auto;
        width: auto;
        max-width: 100%;
        -ms-interpolation-mode: bicubic;
        display: inline-block;
        vertical-align: top;
        border: 0;
        box-sizing: border-box;
        overflow-clip-margin: content-box;
        overflow: clip;
        aspect-ratio: auto 1440 / 1080;
        line-height: 1.5;
        min-width: 304px;

    }
}


@media screen and (min-width: 1200px) {
    .our-history-content-wrapper {
        max-width: 1200px;
    }
}


@media screen and (max-width: 785px) {
    .our-history-content-wrapper {
        flex-direction: column;
    }

    .our-history-content-wrapper .history-column {
        width: 100%;
        margin: 0;
    }
}
/* Keyframe for sliding the button down */
@keyframes buttonSlideDown {
    0% {
      opacity: 1;
      bottom: 0;
    }
    100% {
      opacity: 0;
      bottom: -47px;
    }
  }
  
  /* Keyframe for sliding the button up */
  @keyframes buttonSlideUp {
    0% {
      opacity: 0;
      bottom: -47px;
    }
    100% {
      opacity: 1;
      bottom: 0;
    }
  }
  
  .persistent-button-wrapper.modal-open {
    animation: buttonSlideDown 0.5s forwards; /* Slide down when opening */
    position: fixed;
    right: 0px;
    z-index: 2147483640;
    margin: 10px 170px 0 10px;
    text-align: center;
    box-sizing: border-box;
    pointer-events: none; /* Disable interactions when hidden */
  }
  
  .persistent-button-wrapper.modal-closed {
    animation: buttonSlideUp 0.5s forwards; /* Slide up when closing */
    position: fixed;
    right: 0px;
    z-index: 2147483640;
    margin: 10px 170px 0 10px;
    text-align: center;
    box-sizing: border-box;
    pointer-events: all; /* Enable interactions when visible */
  }
  
  
  .persistent-button-wrapper .persistent-button {
    vertical-align: baseline;
    text-align: left;
    line-height: normal;
    float: none;
    margin: 0;
    padding: 0;
    box-sizing: content-box;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 12px;
  }
  
  .persistent-button-wrapper .persistent-button button {
    box-sizing: border-box;
    display: inline-block;
    padding: 2px 30px 0;
    min-width: 200px;
    line-height: 45px;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    font-size: 1.33333em;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    color: #000000;
    background-color: #f7f934;
    border: none;
    vertical-align: baseline;
    float: none;
    margin: 0;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: rgb(251, 253, 96);
    }

    &:focus {
      outline-offset: 5px;
    }
  }
  

  @media screen and (max-width: 785px) {
    .persistent-button-wrapper {
      right: 19px !important;
      bottom: 12px !important;
      margin: 0 !important;
      width: 100%;
      padding-left: 169px !important;
      z-index: 1000 !important;
      position: relative;

      button {
        border-radius: 50px !important;
        min-width: 160px !important;
        width: 100%;
        z-index: 1001 !important;
        position: relative;
      }
    }
  }
.mobile-contact-menu-backdrop {
    height: 100%;
    opacity: 0;
    transition: opacity 0.45s ease, z-index 0.45s ease;
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 2147483641;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    font-size: 12px;
}

.mobile-contact-menu-backdrop.open {
    opacity: 1;
}

.mobile-contact-menu-backdrop.closed {
    opacity: 0;
    z-index: -1;
}

.mobile-contact-menu-backdrop .mobile-contact-menu {
    position: relative;
    height: 100%;
    overflow: auto;
    width: 100%;
    outline: none;
    vertical-align: baseline;
    text-align: left;
    line-height: normal;
    float: none;
    box-sizing: content-box;
    padding: 0;
    margin: 0 auto !important;
}

.mobile-contact-menu-backdrop .mobile-contact-menu .mobile-contact-menu-items {
    position: absolute;
    bottom: 52px;
    max-height: 100%;
    overflow: auto;
    width: 100%;
    outline: none;
    vertical-align: baseline;
    text-align: left;
    line-height: normal;
    float: none;
    margin: 0;
    padding: 0;
    box-sizing: content-box;
    margin-bottom: 1.5rem;
}


.mobile-contact-menu-backdrop .mobile-contact-menu .mobile-contact-menu-items .mobile-contact-menu-header {
    color: white;
    font-size: 2rem;
    margin: 0px 20px 25px;
}



.mobile-contact-menu-backdrop .mobile-contact-menu .mobile-contact-menu-items .mobile-contact-menu-item {
    color: #000;
    border-radius: 50%;
    text-align: center;
    position: relative;
    display: block;
    margin: 0px 20px 25px;
    width: 50px;
    height: 50px;
    line-height: 27px;
    text-decoration: none;
    float: none;
    padding: 0;
    box-sizing: content-box;
    cursor: pointer;

    span {
        transition: all 0.3s ease-in-out 0.56s;
        opacity: 1;
        width: auto !important;
        line-height: 50px;
        height: 50px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        outline: none;
        vertical-align: baseline;
        float: none;
        margin: 0;
        padding: 0;
        box-sizing: content-box;
        font-family: "Open Sans", Arial, sans-serif;
        font-size: 15px;
        color: white;
        letter-spacing: 1px;
        font-size: 1.13em;
        

        i {
            font-size: 18px;
            color: black !important;
            background-color: #f7f934;
            padding: 20px;
            border-radius: 50%;
            

            &:hover {
                background-color: rgb(251, 253, 96);
            }

            &:focus {
                background-color: rgb(251, 253, 96);
                outline-offset: 3px;
            }
        }
    }
}

.mobile-contact-menu-backdrop .mobile-contact-menu .mobile-contact-menu-close {
    position: absolute;
    bottom: 20px;
    font-size: 28px;
    text-decoration: none;
    vertical-align: baseline;
    text-align: left;
    line-height: normal;
    float: none;
    display: initial;
    width: auto;
    height: auto;
    background: none;
    color: white;
    font-weight: bold;
    border: none;
    cursor: pointer;
    margin: 0px 17px 10px;

    i {
        margin: 15px;
    }
}
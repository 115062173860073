/* Keyframes for sliding the modal up (open) */
@keyframes modalSlideUp {
    0% {
      transform: translate(0, 110%);
      opacity: 0;
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  
  /* Keyframes for sliding the modal down (close) */
  @keyframes modalSlideDown {
    0% {
      transform: translate(0, 0);
      opacity: 1;
    }
    100% {
      transform: translate(0, 110%);
      opacity: 0;
    }
  }
  
  /* When the modal is open, apply the slideUp animation */
  .contact-modal-position-wrapper.modal-open {
    animation: modalSlideUp 0.5s ease forwards;
  }
  
  /* When the modal is closed, apply the slideDown animation */
  .contact-modal-position-wrapper.modal-closed {
    animation: modalSlideDown 0.5s ease forwards;
  }
  
  /* Base styling for the modal container */
  .contact-modal-position-wrapper {
    position: fixed;
    z-index: 2000;
    bottom: 0px;
    right: 0px;
    margin: 10px 80px 0 10px;
    width: 380px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    box-sizing: content-box !important;
  }
  
  .contact-modal-position-wrapper .contact-modal-wrapper {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    outline: none;
    vertical-align: baseline;
    text-align: left;
    line-height: normal;
    margin: 0;
    padding: 0;
    font-family: "Open Sans", Arial, sans-serif;
    box-sizing: content-box;
  }
  
  .contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container {
    opacity: 1;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    box-sizing: content-box;
    outline: none;
    vertical-align: baseline;
    text-align: left;
    line-height: normal;
    margin: 0;
    padding: 0;
    transition: opacity 0.35s ease 0.2s;
  }
  

.contact-modal-position-wrapper.modal-open .contact-modal {
animation: modalSlideUp 0.5s ease forwards;
}
  
  /* When the modal is closed, apply the slideDown animation */
  .contact-modal-position-wrapper.modal-closed .contact-modal {
    animation: modalSlideDown 0.5s ease forwards;
  }
  
  /* Base modal container styling */
  .contact-modal-wrapper .contact-modal-container .contact-modal {
    padding: 25px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    background-color: white;
    box-sizing: content-box;
    opacity: 1; /* Default state is fully visible */
  }
  

.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-close-button {
    opacity: 0.7;
    transition: opacity 0.25s;
    position: absolute;
    top: 14px;
    left: auto;
    right: 15px;
    text-decoration: none;
    box-sizing: content-box;
    vertical-align: baseline;
    text-align: left;
    line-height: normal;
    float: none;
    margin: 0;
    padding: 0;
    animation: jump 0.5s ease 0.2s;
    font-size: larger;
    font-weight: 600;
    cursor: pointer;
}


.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-header {
    outline: none;
    vertical-align: baseline;
    text-align: left;
    line-height: normal;
    float: none;
    margin: 0;
    padding: 0;

    box-sizing: content-box;
    animation: jump 0.5s ease 0.2s;


    &::before {
        box-sizing: border-box;
        content: " ";
        display: table;
    }

    &::after {
        box-sizing: border-box;  
    }
}


.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-header .contact-modal-logo {
    display: inline-block;
    max-width: 76px;
    max-height: 76px;
    overflow: hidden;
    margin-left: 0;
    margin-right: 13px;
    outline: none;
    vertical-align: baseline;
    text-align: left;
    line-height: normal;
    float: none;
    margin: 0;
    padding: 0;
    box-sizing: content-box;
    unicode-bidi: isolate;

    img {
        border-radius: 5px / 5px;
        max-width: 72px;
        max-height: 72px;
        display: inline-block;
        width: auto;
        height: auto;
        outline: none;
        vertical-align: baseline;
        text-align: left;
        line-height: normal;
        float: none;
        margin: 0;
        padding: 0;
        box-sizing: content-box;
        border: 0;
        overflow-clip-margin: content-box;
        overflow: clip;
    }
}


.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-header .contact-modal-title {
    top: -4px;
    max-width: 240px;
    display: inline-block;
    position: relative;
    margin-top: 10px;
    line-height: 1em;
    letter-spacing: 1px;
    font-size: 24px !important;
    float: none;
    margin: 0;
    padding: 0;
    outline: none;
    vertical-align: baseline;
    text-align: left;
    box-sizing: content-box;
    font-family: "Open Sans", Arial, sans-serif;
    color: #30414f;
}


.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-content {
    margin: 9px 0 20px;
    line-height: 1.6em;
    font-size: 0.9rem;
    padding-left: 0;
    padding-right: 20px;
    outline: none;
    vertical-align: baseline;
    text-align: left;
    box-sizing: content-box;
    padding: 0;
    float: none;
    font-family: "Open Sans", Arial, sans-serif;
    color: #30414f;
    animation: jump 0.5s ease 0.2s;
}

.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-contact-buttons {
    outline: none;
    vertical-align: baseline;
    text-align: left;
    line-height: normal;
    float: none;
    margin: 0;
    padding: 0;
    box-sizing: content-box;
    animation: jump 0.5s ease 0.2s;
}

.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-contact-buttons .contact-modal-contact-button {
    display: block;
    padding: 0 25px;
    line-height: 50px;
    text-decoration: none;
    text-align: center;
    letter-spacing: 1px;
    font-size: 0.95em;
    margin-left: 0;
    margin-right: 60px;
    border-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.2s;
    float: none;
    margin: 0;
    box-sizing: content-box;
    font-family: "Open Sans", Arial, sans-serif;
    color: #30414f;
    background-color: rgb(247, 249, 52);

    &:hover {
        background-color: rgb(252, 255, 88);
    }

    &:focus {
      outline-offset: 5px;
    }
}

.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-contact-buttons .contact-options-toggle {
    transition: color 0.25s;
    float: right;
    position: relative;
    vertical-align: baseline;
    text-align: left;
    line-height: normal;
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border: 1px solid rgba(0, 0, 0, 0.637);
    box-sizing: content-box;
    margin-left: 1rem;
    border-radius: 0.3rem;
    cursor: pointer;

    &::before {
        content: " ";
        display: table;
    }

    &::after {
        content: " ";
        display: table;
        box-sizing: border-box;
    }

    &:focus {
      outline-offset: 10px;
    }
}

.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-contact-buttons .contact-options-toggle .contact-options {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.95);
  z-index: 0;
  left: auto;
  right: 0px;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  position: absolute;
  bottom: 115%;
  padding: 13px 30px 13px 13px;
  border-radius: 4px;
  outline: none;
  background-color: rgba(40, 38, 37, 0.9);
  color: white;
}

/* Show the contact options when visible */
.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-contact-buttons .contact-options-toggle .contact-options.visible {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  z-index: 1;
}

/* Styles for the contact options */
.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-contact-buttons .contact-options-toggle .contact-options a {
  display: block;
  line-height: 25px;
  letter-spacing: 1px;
  margin-bottom: 1px;
  font-size: 0.7em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  float: none;
  margin: 0;
  padding: 0;
  box-sizing: content-box;
  color: #fff;
  width: auto;
  height: auto;
  background: none;
  cursor: pointer;
  text-decoration: none;
}

.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-contact-buttons .contact-options-toggle .contact-options a:hover,
.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-contact-buttons .contact-options-toggle .contact-options a:focus {
  color: #609aff;
}

/* Icons within the contact options */
.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-contact-buttons .contact-options-toggle .contact-options a::before {
  width: 34px;
  vertical-align: middle;
  line-height: 25px;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  text-decoration: inherit;
}

/* Clear floats */
.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-contact-buttons .contact-options-toggle .contact-options a::after {
  content: "";
  display: table;
  clear: both;
  box-sizing: border-box;
}

/* Arrow/indicator for the options dropdown */
.contact-modal-position-wrapper .contact-modal-wrapper .contact-modal-container .contact-modal .contact-modal-contact-buttons .contact-options-toggle .contact-options::after {
  content: " ";
  height: 8px;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0px;
}

@keyframes jump {
    0% {
        -webkit-transform: translate(0, 80%);
        -moz-transform: translate(0, 80%);
        -ms-transform: translate(0, 80%);
        -o-transform: translate(0, 80%);
        transform: translate(0, 80%);
    }
    50% {
        -webkit-transform: translate(0, -8px);
        -moz-transform: translate(0, -8px);
        -ms-transform: translate(0, -8px);
        -o-transform: translate(0, -8px);
        transform: translate(0, -8px);
    }
    100% {
        -webkit-transform: translate(0, 0);
        -moz-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@media screen and (max-width: 785px) {
  .contact-modal-position-wrapper {
    bottom: 0px;
    right: 0px;
    margin: 0;
    width: 100%;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    box-sizing: content-box !important;
  }
}
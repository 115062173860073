.page-content-container .clear-section {
    position: relative !important;
    background-position: 50% 50% !important;
    background-attachment: fixed !important;
    background-size: cover !important;
    background-repeat: no-repeat;
    width: 100%;
    max-width: none;
    border: 0 solid #333;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    font-size: 18px;
    line-height: initial;
    word-wrap: break-word;
    background-image: url('../../assets/images/gallery/gallery1.webp') !important;
    margin: 0;
    padding: 50px 70px;


    &::before {
        background-color: rgba(0, 0, 0, 1) !important;
        opacity: 0.6 !important;
        display: block !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        content: ' ' !important;
        border-radius: inherit;
        box-sizing: border-box;
    }

    &::after {
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
    }
}

.page-content-container .clear-section .section-wrapper {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    unicode-bidi: isolate;
}


.page-content-container .clear-section .section-wrapper .section-content-container {
    margin: 0;
    padding: 0;
}

.page-content-container .clear-section .section-wrapper .section-content-container .section-title {
    max-width: 100%;
    line-height: 1.5;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    box-sizing: border-box;
    unicode-bidi: isolate;
    display: block;

    h2, h2 span {
        text-decoration: inherit;
        font-size: 35px;
        line-height: initial;
        color: white;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
        margin: 0;
    }
}

.section-content {
    line-height: 1.5;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    display: block;
    unicode-bidi: isolate;
    box-sizing: border-box;
    color: inherit;

    p, p span {
        margin: 0;
    }

    ul {
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        display: block;
        list-style-type: disc;
        margin: 0;
        unicode-bidi: isolate;
        word-wrap: break-word;
        box-sizing: border-box;


        li {
            line-height: 1.5;
            font-weight: 400;
            font-family: Roboto;
            direction: ltr;
            font-style: normal;
            text-decoration: none;
            list-style-type: disc;
            margin: 0 1em;
            margin-inline-end: 0;
            margin-inline-start: 2em;
            vertical-align: top;
            display: list-item;
            unicode-bidi: isolate;
            box-sizing: border-box;
            font-size: 18px;

            
            &::before {
                min-width: 1em;
                display: inline-block;
                margin-right: .5em;
                margin-inline-start: 0;
                margin-inline-end: .5em;
                font-weight: 700;
                font-family: Arial;
            }
        }
    }

    h2, h2 span {
        color: inherit !important;
    }

    h3, h3 span {
        color: inherit !important;
    }
}


.clear-section .section-content {
    color: white;

    ul, li {
        color: white;
    }
}



.page-content-container .cta-section {
    background-position: 50% 50% !important;
    background-color: rgba(0, 0, 0, 1) !important;
    margin: 0;
    padding: 50px 70px;
    max-width: 100%;
}

.cta-section.clear {
    position: relative !important;
    background-position: 50% 50% !important;
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    background-image: url('../../assets/images/ctaBackground.webp') !important;
    background-size: cover !important;
    background-color: rgba(0, 0, 0, 1) !important;
    display: block;
    unicode-bidi: isolate;
    
    &::before {
        background-color: rgba(0, 0, 0, 1) !important;
        opacity: 0.6 !important;
        display: block !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        content: ' ' !important;
        border-radius: inherit;
        box-sizing: border-box;
    }

    &::after {
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
    }
}


.page-content-container .cta-section .cta-content-wrapper {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    unicode-bidi: isolate;
    word-wrap: break-word;
    justify-content: center;
}

.page-content-container .cta-section .cta-content-wrapper .cta-content-container {
    margin: 0;
    padding: 0;
}

.page-content-container .cta-section .cta-content-wrapper .cta-content-container .cta-content {
    position: relative !important;
    height: auto !important;
    padding-top: 2px !important;
    padding-left: 0px !important;
    padding-bottom: 2px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    max-width: 100% !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    padding-right: 0px !important;
    min-width: 25px !important;
    line-height: 1.5;
    color: white !important;

    h2, h2 span {
        text-decoration: inherit;
        font-size: 35px;
        line-height: initial;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
        margin: 0;
        color: white !important;
    }
}

@media screen and (min-width: 1200px) {
    .page-content-container .clear-section .section-wrapper,
    .page-content-container .cta-section .cta-content-wrapper {
        max-width: 1200px;
    }
}


@media screen and (max-width: 785px) {
    .page-content-container .clear-section {
        padding: 30px 15px !important;
        background-size: auto !important;
        background-position: unset !important;
        background-attachment: unset !important;
    }

    .page-content-container .cta-section {
        padding: 30px 15px !important;
    }

    .page-content-container .cta-section.clear {
        padding: 30px 15px !important;
        background-size: fill !important;
        background-position: 75% 25% !important;
        background-attachment: unset !important;
    }
}
.navbar {
    text-align: center;
    padding: 15px 70px !important;
    margin: 0 !important;
    width: 100% !important;
    border-bottom-style: solid !important;
    border-bottom-color: #000 !important;
    border-bottom-width: 5px !important;
    position: relative;
    max-width: none;
    box-sizing: border-box;
}

.navbar-container {
    display: flex;
    position: relative;
    margin: 0 auto !important;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    color: #666;
    font-size: 18px;
    font-weight: 400;
    justify-content: center; /* Ensure the navbar container is centered */
}

.navbar-wrapper {
    padding-left: 10px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
}

.navlink-container {
    min-width: 9em !important;
    text-align: center !important;
    margin: 0 !important;
    padding: 0 !important;
    justify-content: center !important;
    align-items: stretch !important;
    width: 100%; /* Full width to allow wrapping */
    max-width: 1200px !important; /* Keep centered and within a max width */
    height: auto !important;
    float: none !important;
    position: relative !important;
    color: #000 !important;
    line-height: 1.5;
}

.navlink-list {
    display: flex;
    flex-wrap: wrap !important; /* Enable wrapping */
    justify-content: center; /* Keep items centered */
    list-style-position: inside;
    padding-block-start: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    vertical-align: top;
    border: 0 solid #333;
    /*min-width: 618px;*/
}

.navlink-wrapper {
    display: flex;
    justify-content: flex-start;
    position: relative;
    font-weight: 400;
    vertical-align: top;
    box-sizing: border-box;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
    list-style-position: inside;
    list-style: none;
    margin-bottom: 0.2rem;
}

.navlink {
    font-size: 17.5px !important;
    color: #000 !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    padding: 0 15px;
    margin: 0;
    font-weight: inherit;
    letter-spacing: .03em;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    vertical-align: inherit;
    box-sizing: border-box;
}

.navlink span {
    position: relative;
    display: inline-block;
    line-height: 2;
    flex-wrap: wrap;
    color:#000;
}

.navlink span::after {
    content: '';
    position: absolute;
    bottom: 0rem;
    left: 0;
    width: 0;
    height: 2px;
    background-color: currentColor;
    opacity: 0;
    transition: width 0.2s ease-out, opacity 0.2s ease-out;
    margin-bottom: -0.13rem;
}

.navlink:hover span::after,
.navlink.active span::after {
    width: 100%;
    opacity: 1;
}



/* Adjust for smaller screens to ensure items remain centered and wrap */
@media screen and (max-width: 1200px) {
    .navlink-list {
        justify-content: center; /* Keep links centered even when wrapping */
    }
}

.page-wrapper .content-wrapper {
    margin: 0;
    padding: 50px 70px;
    width: 100%;
    max-width: none;
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
}


.page-wrapper .content-wrapper .page-content {
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
}


.page-wrapper .content-wrapper .page-content .page-content-wrapper .page-title-wrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    width: 100%;
    max-width: none;
    line-height: 1.5;
    position: relative;
    font-size: 18px;
}


.page-wrapper .content-wrapper .page-content .page-content-wrapper .page-title-wrapper .page-title {
    font-size: 18px;
    color: #000;
    font-family: "PT Sans";
    font-weight: 400;
    line-height: 1.5;
    max-width: 100%;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    box-sizing: border-box;


    h1 {
        text-decoration: inherit;
        font-size: 45px;
        line-height: initial;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
        margin: 0;

        span {
            display: unset;
            color: inherit;
        }
    }
}


.page-wrapper .content-wrapper .page-content .page-content-wrapper .testimonial-row-wrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin: 0;
    line-height: 1.5;
    width: 100%;
    max-width: none;
    position: relative;
    box-sizing: border-box;
}

.page-wrapper .content-wrapper .page-content .page-content-wrapper .testimonial-row-wrapper .testimonial-row:first-child {
    margin-top: 0;
}


.testimonial-row {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    line-height: 1.5;
    margin-top: 20px;
}

.testimonial-row-wrapper .testimonial-row .review-card:first-child {
    margin-left: 0 !important;
}

.testimonial-row-wrapper .testimonial-row .review-card:last-child {
    margin-right: 0 !important;
}

.testimonial-row-wrapper .testimonial-row .review-card {
    margin-left: 10px !important;
    margin-right: 10px !important;
    padding-top: 20px !important;
    padding-left: 20px !important;
    padding-bottom: 20px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    
    padding-right: 20px !important;
    border-color: rgba(0, 0, 0, 1) !important;
    border-width: 1px !important;
    border-style: solid !important;
    font-size: 18px;
    color: #000;
    font-family: "PT Sans";
    font-weight: 400;
    position: relative;
    width: 25%;
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
}

.review-card .review-content {
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.5;
    max-width: 100%;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    word-wrap: break-word;

    p {
        margin: 0;
        
    }
}



@media screen and (min-width: 1200px) {
    .page-wrapper .content-wrapper .page-content {
        max-width: 1200px;
    }

    .page-wrapper .content-wrapper .page-content .page-content-wrapper .testimonial-row-wrapper .testimonial-row {
        max-width: 1200px ;
    }
}

@media screen and (max-width: 785px) {
    .page-wrapper .content-wrapper {
        padding: 30px 15px;
    }

    .testimonial-row-wrapper .testimonial-row {
        flex-direction: column;
        gap: 20px
    }
    .testimonial-row-wrapper .testimonial-row .review-card {
        width: 100% !important;
        margin: 0 !important;
    }
}


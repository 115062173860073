.contactbar {
    margin: 0 !important;
    padding: 15px 70px 20px !important;
    width: auto !important;
    min-width: 768px;
    background-color: inherit;
    border-bottom-width: 2px !important;
    border-bottom-style: solid !important;
    border-bottom-color: #000 !important;
    box-sizing: border-box;
}

.contactbar .col-wrapper {
    display: flex !important;
    position: relative !important;
    margin: 0 auto ;
    width: 100% !important;
}

@media screen and (min-width: 1200px) {
    .contactbar .col-wrapper {
        max-width: 1200px;
    }
}

.contactbar .col-wrapper .col-item * {
    line-height: 1.5;
}

.contactbar .col-wrapper .col-item {
    display: flex;
    margin: 0 !important;
    padding: 0 10px 0 0 !important;
    position: relative;
    width: 33.33333%;
}


.contactbar .col-wrapper .col-item.navLogo {
    align-items: center;
}

.contactbar .col-wrapper .col-item.navLogo .image-wrapper {
    width: calc(100% - 0px) !important;
    height: auto !important;
    max-width: 281.74px !important;
    margin: 0 59.9896px 0 0 !important;
    min-width: 12rem !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
    padding: 0 !important;
    text-align: center !important;
    display: block !important;
}


.contactbar .col-wrapper .col-item.navLogo .image-wrapper img{
    max-width: 100%;
    height: auto;
    
}

.contactbar .col-wrapper .col-item.row-stack {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    margin: 0 !important;
    padding: 0 10px !important;
}

.socials-row {
    position: relative;
    height: auto;
    max-width: 100%;
    padding: 0;
    text-align: center;
    margin: 10px auto 0;
}

.socials-wrapper {
    display: flex;
    justify-content: center;
    gap: 10px; /* Space between icons */
}

.social-icon {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &:focus {
        outline-offset: 5px;
    }
}

.social-icon i {
    font-size: 17px;
    color: white;
}

/* Individual background colors */
.facebook {
    background-color: #1877f2; /* Facebook blue */
}

.google {
    background-color: #477cdf; /* Google blue */
}

.yelp {
    background-color: #e43340; /* Yelp red */
}


.contactbar .col-wrapper .col-item.row-stack .email{
    line-height: 1.5;
    font-size: 18px;
    font-family: Roboto;
}

.contactbar .col-wrapper .col-item.row-stack .email a{
    text-decoration: none;
    font-weight: bold;
    display: initial;
    word-break: break-all;
    color: inherit
}

.contactbar .col-wrapper .col-item.contact-button span {
    display: inline !important;
    color: black !important;
    background-color: transparent !important;

    i {
        background-color: transparent !important;
    }
}

.button-icon-wrapper {
    font-size: 18px;
    position: absolute;
    width: 50px;
    left: 0;
}

.button-text {
    font-size: 20px;
    padding: 10px 7px !important;
    max-width: 98%;
    display: inline-block !important;
    line-height: normal;
    white-space: normal;
    vertical-align: middle;
}

.contactbar .col-wrapper .col-item.contact-button {
    margin: 0 !important;
    padding: 0 0 0 10px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    font-size: 18px;
    font-family:  Roboto;
    font-weight: 400;
    white-space-collapse: collapse;
    text-align: center !important;
    

    a {
        text-decoration: none;
        clear: both;
        position: relative;
        text-align: center !important;
        line-height: 22px;
        cursor: pointer;
        border-style: solid;
        white-space: nowrap;
        transition: background-color .2s ease, border-color .2s ease;
        max-width: 100% !important;

        display: flex;
        justify-content: center; /* Centers text and icon */
        align-items: center; /* Centers vertically */
        width: 280px;
        height: 60px;
        background-color: rgb(247, 249, 52) !important;
        text-decoration: none;
        border-radius: 50px;
        border: 1px solid black;

        text-align: center; /* Keeps text centered */
        margin: 10px 0 10px auto!important;
        padding: 0 0 0 20px!important;


        &:hover {
            background-color: white !important;
        }

        &:focus {
            outline-offset: 8px;
        }
    }
}







.not-found-wrapper {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 120px;
    background: #fff;
    color: #bababa;
    text-align: center;
    font-family: 'Source Sans Pro';
    overflow: hidden;
    max-width: none;

    &::before {
        content: "";
        display: table;
        text-align: center;
    }

    &::after {
        content: "";
        display: table;
        text-align: center;
    }
}

.not-found-wrapper .not-found-container {
    margin: 0;
    padding: 50px 70px;
    width: 100%;
    max-width: none;
    position: relative;
    box-sizing: border-box;
    display: block;

    &::before {
        content: ' ';
        display: table;
    }

    &::after {
        content: ' ';
        display: table;
        clear: both;
        box-sizing: border-box;
    }
}

.not-found-wrapper .not-found-container .not-found {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    word-wrap: break-word;
}

.not-found-wrapper .not-found-container .not-found .four-o-four {
    line-height: 1.5;
    max-width: 100%;
    font-family: 'Oswald', sans-serif;
    font-size: 130px;
    text-align: center;
    box-sizing: border-box;
    display: block;
    color: #000;
    font-weight: 400;

}

.not-found-wrapper .not-found-container .not-found .four-o-four .lean {
    display: inline-block;
    transform: rotate(15deg) translate(-5px, -4px);
    font-family: 'Oswald', sans-serif;
    font-size: 130px;
    text-align: center;
}

.not-found-wrapper .not-found-container .not-found .not-found-description {
    line-height: 1.5;
    max-width: 100%;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    text-align: center;
    font-size: 22px;
    margin: 45px 0;
}

.not-found-wrapper .not-found-container .not-found .not-found-home-button {
    line-height: 1.5;
    border-radius: 2px;
    box-sizing: border-box;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    width: 184px;
    height: 37px;
    margin-bottom: 20px;
    background: #898989;
    color: #fff;
    text-align: center;
    font-size: 16px;
    outline: 0;
    vertical-align: inherit;
    font-family: Roboto;
    font-weight: 400;
}
.footer {
    position: relative;
    border: 0 solid #333;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: initial;
    word-wrap: break-word;
}

.footer .footer-wrapper {
    margin: 0 !important;
    padding: 0 !important;
    background-color: transparent !important;
    font-size: 1em;
    font-weight: 400;
    text-align: left;
    background: #333;
    box-sizing: border-box;
    border: 0 solid #333;
    display: block;
    unicode-bidi: isolate;
    font-family: Roboto;
    word-wrap: break-word;
    box-sizing: border-box;
}

.footer .footer-wrapper .footer-content-wrapper {
    border: 0 solid #333;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    color: #f7f7f7;
    font-size: 1em;
    font-weight: 400;
    text-align: left;
    font-family: Roboto;
    line-height: initial;
}

.footer .footer-wrapper .footer-content-wrapper .footer-info-section-wrapper {
    text-align: center;
    margin: 0 !important;
    padding: 50px 70px !important;
    width: auto !important;
    background-color: #000 !important;
    max-width: none;
    position: relative;
    border: 0 solid #333;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    font-size: 1em;
    font-weight: 400;
    font-family: Roboto;

    &::before {
        content: ' ';
        display: table;
        box-sizing: border-box;
    }

    &::after {
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
    }
}


.footer .footer-wrapper .footer-content-wrapper .footer-info-section-wrapper .footer-info-section-container {
    display: block;
    position: relative;
    margin: 0 auto;

    box-sizing: border-box;
    unicode-bidi: isolate;
    text-align: center;
    font-size: 1em;
    font-weight: 400;
    text-align: center;
    font-family: Roboto;
    word-wrap: break-word;

    &::before {
        box-sizing: border-box;

    }

    &::after {
        box-sizing: border-box;
    }
}



.footer .footer-wrapper .footer-content-wrapper .footer-info-section-wrapper .footer-info-section-container .footer-info-section {
    margin: 0;
    padding: 0;
}

.footer .footer-wrapper .footer-content-wrapper .footer-info-section-wrapper .footer-info-section-container .footer-info-section .footer-info {
    padding: 0 !important;
    margin: 0;
    line-height: 1.5;
    width: 100%;
    max-width: none;
    position: relative;
    border: 0 solid #333;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    text-align: center;
    word-wrap: break-word;

    &::before {
        content: ' ';
        display: table;
        box-sizing: border-box;
    }

    &::after {
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
    }
}


.footer .footer-wrapper .footer-content-wrapper .footer-info-section-wrapper .footer-info-section-container .footer-info-section .footer-info .footer-columns-wrapper {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100% !important;
    border: 0 solid #333;
    box-sizing: border-box;
    unicode-bidi: isolate;
    line-height: 1.5;
    font-size: 18px;    color: #000;
    font-family: Roboto;
    font-weight: 400;
    text-align: center;
}

.footer-info-section .footer-info .footer-columns-wrapper .footer-column:first-child {
    padding-right: 20px;
}

.footer-info-section .footer-info .footer-columns-wrapper .footer-column:nth-child(2) {
    padding-left: 20px;
}

.footer-info-section .footer-info .footer-columns-wrapper .footer-column:nth-child(2) * {
    text-align: right;
}


.footer-columns-wrapper .footer-column:nth-child(2) .socials-wrapper {
    justify-content: flex-end;
}

.footer .footer-wrapper .footer-content-wrapper .footer-info-section-wrapper .footer-info-section-container .footer-info-section .footer-info .footer-columns-wrapper .footer-column {
    margin: 0 !important;
    position: relative;
    width: 100% !important;
    display: inline-block;
    vertical-align: top;
    border: 0 solid #333;
    box-sizing: border-box;
    unicode-bidi: isolate;
    line-height: 1.5;
    text-align: center;
    word-wrap: break-word;
}

.footer-info .footer-columns-wrapper .footer-column .footer-column-section-header {
    font-size: 18px;
    line-height: 1.5;
    max-width: 100%;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    display: block;
    unicode-bidi: isolate;

    h6, h6 span {
        color: white;
        text-decoration: inherit;
        font-size: 18px;
        line-height: initial;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
        display: block;
        unicode-bidi: isolate;
        margin: 0;
    }
}


.footer-column .footer-nav {
    min-width: 9em !important;
    text-align: initial !important;
    padding: 0 !important;
    margin: 0 !important;
    color: #fff !important;
    line-height: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    overflow: visible;
    max-width: 100%;
    font-family: Roboto;
    font-weight: 400;
    unicode-bidi: isolate;
    box-sizing: border-box;
    border: 0 solid #333;
}



.footer-column .footer-nav .footer-nav-list {
    flex-direction: column;
    flex: 1 1 100%;
    list-style-position: inside;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    vertical-align: top;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
}



.footer-column .footer-nav .footer-nav-list .footer-navlink-wrapper {
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    position: relative;
    font-weight: 400;
    border: 0 solid #333;
    vertical-align: top;
    box-sizing: border-box;
    unicode-bidi: isolate;
    list-style-position: inside;
    list-style: none;
    color: #fff !important;
    line-height: 1.5;
    font-family: Roboto;

    &::before {
        content: '';
        align-self: center;
        font-weight: 700;
        box-sizing: border-box;
    }

    &::after {
        content: '' !important;
        width: 100%;
        height: 0;
        color: #d1d1d1;
        align-self: flex-start;
        border: 0 solid currentColor;
        font-weight: 700;
        box-sizing: border-box;
    }
}


.footer-nav .footer-nav-list .footer-navlink-wrapper .footer-navlink.active {
    color:#f7f934;

}

.footer-nav .footer-nav-list .footer-navlink-wrapper .footer-navlink {
    margin: 0 !important;
    padding: 13px 0 !important;
    transition: color .2s ease-out;
    flex: 1 1 auto;
    font-weight: inherit;
    letter-spacing: .03em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    position: relative;

    vertical-align: inherit;
    border: 0 solid #333;
    box-sizing: border-box;
    font-size: 1rem;
    width: fit-content;

    span {
        pointer-events: none;
        white-space: initial;
        cursor: pointer;
        color: inherit;
        font-weight: inherit;
        letter-spacing: .03em;
        list-style: none;
        font-size: 1rem;
        font-family: Roboto;

        &::before {
            content: '';
            width: 100%;
            height: 0;
            border: 0 solid transparent;
            border-top-color: currentColor;
            display: block;
            transition: transform .2s ease-out, opacity .2s ease-out;
            opacity: 0;
        }

        &::after {
            content: '';
            width: 100%;
            height: 0;
            border: 0 solid transparent;
            border-top-color: currentColor;
            display: block;
            transition: transform .2s ease-out, opacity .2s ease-out;
            opacity: 0;
            box-sizing: border-box;
        }
    }

    &:hover {
        color: #f7f934;
    }

}


.footer-contact-method-wrapper {
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: 1.5;
    max-width: 100%;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    display: block;
    unicode-bidi: isolate;

    p, p span {
        line-height: 1.5;
        font-weight: 400;
        font-family: Roboto;
        font-style: normal;
        text-decoration: none;
        color: white;
        display: block;
        unicode-bidi: isolate;
        margin: 0;

        a {
            color: #f7f934;
            font-weight: bold;
        }
    }
}

.footer-hours-wrapper {
    width: calc(100% - 0px) !important;
    margin: 10px 0 0 !important;
    min-width: 25px !important;
    text-align: center !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
    height: auto !important;
    max-width: 100% !important;
    padding: 0 !important;
    line-height: 1.5;
    clear: both;
    display: block;
    unicode-bidi: isolate;

    dl {
        padding: 0;
        margin: 0;
        background: 0 0;
        border: 0;
        unicode-bidi: isolate;
        display: block;
        margin: 0;
    }
}

.footer-hours-wrapper .hours-item-wrapper:first-child {
    padding-bottom: 10px !important;
}

.footer-hours-wrapper .hours-item-wrapper {
    overflow: hidden;
    padding-bottom: 10px;
    display: block;
    unicode-bidi: isolate;
    text-align: center !important;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    width: 100%;

    dt {
        margin: 0;
        border: 0 solid #333;
        color: #fff !important;
        width: 70%;
        float: left;
        text-align: left;
        display: block;
        unicode-bidi: isolate;
        line-height: 1.5;
        box-sizing: border-box;
    }

    dd {
        color: #fff !important;
        float: right;
        text-align: right;
        width: 30%;
        margin: 0;
        display: block;
        unicode-bidi: isolate;
        line-height: 1.5;
        font-size: 18px;
        font-family: Roboto;
        font-weight: 400;

        time {
            color: #fff !important;
            border: 0 solid #333;
            box-sizing: border-box;
            text-align: right;
            line-height: 1.5;
            font-family: Roboto;
            font-weight: 400;
            word-wrap: break-word;
        }
    }
}

.footer-column .footer-text-wrapper {
    font-size: 18px;
    font-family: Roboto;
    font-weight: 400;
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;
    max-width: 100%;
    line-height: 1.5;
    display: block;
    unicode-bidi: isolate;
    word-wrap: break-word;
    box-sizing: border-box;

    p, p span {
        line-height: 1.5;
        font-weight: 400;
        font-family: Roboto;
        font-style: normal;
        text-decoration: none;
        direction: ltr;
        margin: 0;
        unicode-bidi: isolate;
        display: block;
        text-align: left;
        word-wrap: break-word;
        box-sizing: border-box;
        color: white;
    }
}


.footer .footer-wrapper .footer-content-wrapper .footer-legal-section-wrapper {
    margin: 0 !important;
    padding: 50px 70px 70px !important;
    width: auto !important;
    max-width: none;
    position: relative;
    display: block;
    unicode-bidi: isolate;
    box-sizing: border-box;
    color: #f7f7f7;
    font-size: 1em;
    font-weight: 400;
    text-align: left;
    font-family: Roboto;
    word-wrap: break-word;

    &::before {
        content: ' ';
        display: table;
        box-sizing: border-box;
    }

    &::after {
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
    }
}

.footer .footer-wrapper .footer-content-wrapper .footer-legal-section-wrapper .footer-legal-section-container {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    flex-direction: column;
}

.footer .footer-wrapper .footer-content-wrapper .footer-legal-section-wrapper .footer-legal-section-container .footer-credit {
    display: flex;
    justify-content: center !important;
    

    p{
        color: #000;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }

    a {
        color: inherit;
        transition: color 0.15s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            color:#e4a906
        }
    }

}

.footer .footer-wrapper .footer-content-wrapper .footer-legal-section-wrapper .footer-legal-section-container .footer-legal-content-wrapper {
    margin: 0;
    padding: 0;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
}

.footer .footer-wrapper .footer-content-wrapper .footer-legal-section-wrapper .footer-legal-section-container .footer-legal-content-wrapper .footer-legal-content {
    line-height: 1.5;
    padding: 15px 150px;
    text-align: center;
    font-size: 12px;
    color: #000;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    font-family: Roboto;
    font-weight: 400;
    word-wrap: break-word;
}

@media screen and (min-width: 1200px) {
    .footer .footer-wrapper .footer-content-wrapper .footer-info-section-wrapper .footer-info-section-container,
    .footer .footer-wrapper .footer-content-wrapper .footer-legal-section-wrapper .footer-legal-section-container {
        max-width: 1200px;
    }

    .footer .footer-wrapper .footer-content-wrapper .footer-info-section-wrapper .footer-info-section-container .footer-info-section .footer-info .footer-columns-wrapper {
        max-width: 1200px;
    }
}


@media screen and (max-width: 785px) {
    .footer .footer-wrapper .footer-content-wrapper .footer-info-section-wrapper {
        padding: 30px 15px !important;
    }

    .footer .footer-wrapper .footer-content-wrapper .footer-legal-section-wrapper .footer-legal-content {
        padding: 0 !important;
    }

    .footer .footer-wrapper .footer-content-wrapper .footer-legal-section-wrapper {
        padding: 30px 15px !important;
        margin-bottom: 3rem !important;
    }


    .footer-hours-wrapper {
        width: 100%;
        display: flex;
        justify-content: center !important;

        dl {
            width: 100%;
        }
    }

    .footer-hours-wrapper .hours-item-wrapper {
        


        dt {
            width: 40% !important;
        }

        dd {
            width: 60%;
            text-align: right !important;
            float: right;
        }
    }
}



@media screen and (max-width: 490px) {
    .footer .footer-wrapper .footer-content-wrapper .footer-info-section-wrapper .footer-info-section-container .footer-info-section .footer-info .footer-columns-wrapper {
        flex-direction: column;
        width: 100% !important;
        gap: 40px;

        div {
            width: 100% !important;
            padding: 0;
        }
    }


    .footer .footer-wrapper .footer-content-wrapper .footer-info-section-wrapper .footer-info-section-container .footer-info-section .footer-info .footer-columns-wrapper .footer-column *{
        text-align: center;
        justify-content: center;
    }

    .footer-column .footer-nav .footer-nav-list .footer-navlink-wrapper {
        justify-content: center;
        align-items: center;
    }

    .footer-hours-wrapper {
        width: 100%;
        display: flex;
        justify-content: center !important;

        dl {
            width: 80%;
        }
    }

    .footer-hours-wrapper .hours-item-wrapper {
        


        dt {
            width: 30%;
        }

        dd {
            width: 50%;
            text-align: right !important;
            float: right;
        }
    }


}
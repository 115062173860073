.quote {
    margin: 0;
    padding: 50px 70px;
    width: 100%;
    max-width: none;
    position: relative;
    border: 0 solid #333;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    font-size: 18px;
    word-wrap: break-word;

    &::before {
        content: ' ';
        display: table;
    }

    &::after {
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
    }
}

.seamless-gutters {
    position: relative !important;
    background-position: 50% 50% !important;
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    background-image: url('../../assets/images/seamlessGuttersBackground.webp') !important;
    background-size: cover !important;
    margin: 0;
    padding: 50px 70px;
    width: 100%;
    max-width: none;
    border: 0 solid #333;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    font-size: 18px;

    &::before {
        background-color: #000 !important;
        opacity: .6 !important;
        display: block !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        content: ' ' !important;
        border-radius: inherit;
        box-sizing: border-box;
        font-size: 18px;
        color: #000;
        font-family: Roboto;
        font-weight: 400;
    }

    &::after {
        clear: both; 
        content: ' ';
        display: table;
        box-sizing: border-box;
        font-size: 18px;
        color: #000;
        font-family: Roboto;
        font-weight: 400;
        line-height: initial;
    }
}

.our-history {
    margin: 0;
    padding: 50px 70px;
    width: 100%;
    max-width: none;
    position: relative;
    border: 0 solid #333;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: initial;
    word-wrap: break-word;

    &::before {
        content: ' ';
        display: table;
        box-sizing: border-box;
    }

    &::after {
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
    }
}

.watkins-difference {
    position: relative !important;
    background-position: 50% 50% !important;
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;
    background-image: url('../../assets/images/watkinsDifferenceBackground.webp') !important;
    background-size: cover !important;
    margin: 0;
    padding: 50px 70px;
    width: 100%;
    max-width: none;
    border: 0 solid #333;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    font-size: 18px;

    &::before {
        background-color: #000 !important;
        opacity: .6 !important;
        display: block !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        content: ' ' !important;
        border-radius: inherit;
        box-sizing: border-box;
        font-size: 18px;
        color: #000;
        font-family: Roboto;
        font-weight: 400;
    }

    &::after {
        clear: both; 
        content: ' ';
        display: table;
        box-sizing: border-box;
        font-size: 18px;
        color: #000;
        font-family: Roboto;
        font-weight: 400;
        line-height: initial;
    }
}

.top-rated {
    margin: 0;
    padding: 50px 70px;
    width: 100%;
    max-width: none;
    position: relative;
    border: 0 solid #333;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    line-height: initial;
    word-wrap: break-word;

    &::before {
        content: ' ';
        display: table;
        box-sizing: border-box;
    }

    &::after {
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
    }
}

.call-to-action {
    position: relative !important;
    background-image: url('../../assets/images/ctaBackground.webp') !important;
    background-position: 50% 50% !important;
    background-attachment: fixed !important;
    width: auto !important;
    height: auto !important;
    margin: 0 !important;
    padding: 156px 70px !important;
    min-width: 0 !important;
    text-align: start !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    max-width: 100% !important;
    display: block;
    unicode-bidi: isolate;
    font-size: 18px;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
    word-wrap: break-word;
    background-repeat: no-repeat !important;
    background-size: cover !important;

    &::before {
        display: block !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        content: ' ' !important;
        border-radius: inherit;
        background-color: #000 !important;
        opacity: .6 !important;
        box-sizing: border-box;

    }
    
    &::after {
        clear: both;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 785px) {
    .quote {
        padding: 30px 15px;
    }

    .seamless-gutters {
        padding: 30px 15px;
        background-size: fill !important;
        background-position: unset !important;
        background-attachment: unset !important;
    }

    .our-history {
        padding: 30px 15px;
    }

    .watkins-difference {
        padding: 30px 15px;
        background-size: fill !important;
        background-position: unset !important;
        background-attachment: unset !important;
    }

    .top-rated {
        padding: 30px 15px;
    }

    .call-to-action {
        padding: 30px 15px !important;
        background-size: fill !important;
        background-position: unset !important;
        background-attachment: unset !important;
    }
}
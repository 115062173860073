.hero-wrapper {
    position: relative !important;
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
    max-width: none !important;
    padding: 100px 70px !important;
    background-position: 50% 50% !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    min-width: 0 !important;
    text-align: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url('../../assets/images/hero.webp');
    

    &::before {
        opacity: .5 !important;
        display: block !important;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
        content: ' ' !important;
        border-radius: inherit;
        box-sizing: border-box;
        text-align: center !important;
        opacity: .5 !important;
        background-color: #000;
    }

    &::after {
        content: ' ';
        display: table;
        clear: both;
        text-align: center !important;
    }
}

.hero-wrapper .hero-content-wrapper {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
}

.captcha-wrapper {
    z-index: 1;
}

.hero-wrapper .hero-content-wrapper .hero-content {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    line-height: 1.5;
    color: #000;
    font-family: Roboto;
    font-weight: 400;

}


.hero-wrapper .hero-content-wrapper .hero-content .free-estimate {
    margin: 0 10px 0 0 !important;
    padding: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    color: #000;
    font-family: Roboto;
    font-weight: 400;

    position: relative;
    width: 50%;
    box-sizing: border-box;
}


.hero-wrapper .hero-content-wrapper .hero-content .free-estimate .estimate-header{
    text-align: left;
    margin: 8px 0;
    padding: 2px 0;

    p {
        line-height: 1.5;
        font-weight: 400;
        font-family: Roboto;
        direction: ltr;
        font-style: normal;
        text-decoration: none;
    }


}


.hero-wrapper .hero-content-wrapper .hero-content .free-estimate .estimate-header p span {
    color: rgb(255, 255, 255) !important;
    font-weight: 700 !important;
    font-size: 45px !important;
    display: unset;
    font-family: 'PT Sans', 'PT Sans Fallback';

}

.estimate-button-wrapper {
    width: 100%;
    justify-content: start;
}

.estimate-button {
    text-decoration: none;
    margin: 10px 0;
    clear: both;
    position: relative;
    text-align: center;
    line-height: 22px;
    cursor: pointer;
    box-shadow: none;
    background-image: none;
    padding: 0;
    height: auto;
    border-style: solid;
    white-space: nowrap;
    transition: background-color .2s ease, border-color .2s ease;
    max-width: 100%;
    display: flex;
    justify-content: center; /* Centers text and icon */
    align-items: center; /* Centers vertically */
    width: 280px !important;
    height: 60px;
    background-color: rgb(247, 249, 52);
    color: black;
    text-decoration: none;
    border-radius: 50px;
    border: 1px solid black;

    text-align: center; /* Keeps text centered */
    margin: 0;
    padding: 0;


    float: none !important;
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
    min-width: 0 !important;
    text-align: center !important;
    
    span {
        padding: 10px 0;
        line-height: normal;
        white-space: normal;
        display: inline-block;
        vertical-align: middle;
        max-width: 98%;
        color: black;
        font-size: 15px;
        font-weight: 400;
        font-family: Roboto;

    }

    &:hover {
        background-color: white;
    }

    &:focus {
        outline-offset: 5px;
    }
}


.form-background {
    margin: 0 0 0 10px !important;
    padding: 20px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    background-color: rgba(0, 0, 0, .8) !important;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 400;
    position: relative;
    width: 50%;
    min-width: 314px;
    vertical-align: top;
    float: left;
    box-sizing: border-box;
    unicode-bidi: isolate;
}

.form-container {
    line-height: 1.5;
    max-width: 100%;
    clear: both;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;
    font-size: 18px;
}

.estimate-form {
    max-width: 960px;
    position: relative;
    margin: 0 auto;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    box-sizing: border-box;
    line-height: 1.5;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 400;
    

    &::before {
        content: ' ';
        display: table;
        box-sizing: border-box;
        line-height: 1.5;
    }

    &::after{
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
    }
}

form .submission-message {
    color: white;
}

.form-title {
    display: none;
}

.contact-form-input-wrapper.half {
    width: calc(49% - 1.875em);
}

.contact-form-input-wrapper.full {
    width: calc(99% - 1.875em);
}

.contact-form-input-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    margin-top: 10px;
    padding-left: .9375em;
    padding-right: .9375em;
    line-height: 1.5;
}

.contact-form-input-wrapper label {
    font-family: Roboto;
    font-weight: 400;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    text-align: left;
    line-height: 1.5;
    word-wrap: break-word;
    color: #fff;
    font-size: 18px;
}

.contact-form-input-wrapper input[type=textarea] {
    font-size: 16px;
    background-color: rgba(102, 102, 102, .15);
    border: 2px solid #fff;
    width: 100%;
    margin: 3px 0;
}

.error-message {
    color: rgb(247, 249, 52);
}

.contact-form-input-wrapper input[type=text],
.contact-form-input-wrapper input[type=email],
.contact-form-input-wrapper input[type=tel],
.contact-form-input-wrapper textarea {
    font-style: normal !important;
    color: #fff !important;
    font-size: 16px;
    background-color: rgba(102, 102, 102, .15);
    border: 2px solid #fff;
    width: 100%;
    margin: 3px 0;
    height: 40px;
    padding: 0 10px;
    line-height: normal;
    font-family: inherit;
    box-sizing: border-box;
    overflow-clip-margin: 0px !important;
    overflow: clip !important;
    padding-block: 1px;
    padding-inline: 2px;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
}

.contact-form-input-wrapper textarea {
    font-size: 16px;
    background-color: rgba(102, 102, 102, .15);
    border: 2px solid #fff;
    width: 100%;
    margin: 3px 0;
    overflow: auto;
    vertical-align: top;
    font-style: normal !important;
    color: #fff !important;
    height: 80px;
    padding: 10px;
    min-height: 50px;
    box-sizing: border-box;
}

.form-submit-wrapper {
    max-width: 100%;
    padding: 0;
    min-width: 280px;
    width: auto;
    height: 60px !important;
    float: left !important;
    margin-left: .9375em;
    margin-right: .9375em;
    margin-top: 1rem !important;

    button {
        font-size: 15px;
        font-family: Roboto !important;
        font-weight: 400;

        &:disabled {
            background-color: rgba(244, 247, 72, 0.836);
            cursor: not-allowed;
        }
    }
}

@media screen and (min-width: 1200px) {
    .hero-wrapper .hero-content-wrapper,
    .hero-wrapper .hero-content-wrapper .hero-content {
        max-width: 1200px;
    }
}

@media screen and (max-width: 785px) {
    .contact-form-input-wrapper {
        width: 100% !important;
        padding: 0;
    }

    .form-background {
        margin: 0 !important;
        min-width: unset;
    }

    .form-submit-wrapper {
        width: 100%;
        margin: 0 !important;
        min-width: unset;
    
        button {
            width: 100% !important;
        }
    }

    .hero-wrapper {
        padding: 50px 15px !important;
    }

    .hero-wrapper .hero-content-wrapper .hero-content {
        flex-direction: column;
        gap: 40px;
        

        div {
            width: 100% !important;
        }
    }

    .estimate-button {
        width: 100% !important;
    }

    .hero-wrapper .hero-content-wrapper .hero-content .free-estimate .estimate-header p {
        margin: 0;
    }

    .hero-wrapper .hero-content-wrapper .hero-content .free-estimate .estimate-header p span {
        font-size: 30px !important;
    }
}
.section-image-container {
    margin-left: 10px !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    position: relative;
    width: 50%;
    vertical-align: top;
    float: left;
    unicode-bidi: isolate;
}

.section-image-container .section-image {
    width: 100% !important;
    line-height: 1.5;
    display: inline-block;
    max-width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    border: 0 solid #333;
    box-sizing: border-box;
    unicode-bidi: isolate;

    img {
        height: auto;
        width: 100%;
        vertical-align: top;
        max-width: 100%;
        display: inline-block;
        border: 0;
        overflow-clip-margin: content-box;
        overflow: clip;
    }
}

.showcase-photo {
    width: 400px !important;
    line-height: 1.5;
    display: inline-block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    unicode-bidi: isolate;
    border: 0 solid #333;
    box-sizing: border-box;
    will-change: transform;
    
    img {
        border-style: solid !important;
        border-width: 4px !important;
        border-color: rgba(255, 255, 255, 1) !important;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 11px 0px !important;
        border-radius: 0% !important;
        height: auto;
        width: 100%;
        transition: all .2s ease-out !important;
        vertical-align: top;
        max-width: 100%;
        display: inline-block;
        aspect-ratio: auto 1920 / 2560;
        box-sizing: border-box;
        line-height: 1.5;
        will-change: transform;
        
        &:hover {
            transform: scale(1.06);
        }
    }
}

.content-columns-wrapper .clear-content-column {
    margin-left: 10px !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding-right: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 400;
    position: relative;
    width: 50%;
    box-sizing: border-box;
    unicode-bidi: isolate;
    line-height: initial;
    word-wrap: break-word;

    p, p span {
        font-style: normal;
        text-decoration: none;
        direction: ltr;
        line-height: 1.5;
        font-weight: 400;
        font-family: Roboto;
        unicode-bidi: isolate;
        display: block;
        margin: 0;
        color: inherit
    }
}

.solid-section {
    margin: 0;
    padding: 50px 70px;
    width: 100%;
    max-width: none;
    position: relative;
    box-sizing: border-box;
    display: block;
    unicode-bidi: isolate;

    &::before {
        content: ' ';
        display: table;
        box-sizing: border-box;
    }

    &::after {
        content: ' ';
        display: table;
        clear: both;
        box-sizing: border-box;
    }
}

.solid-section .content-cols-wrapper {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    unicode-bidi: isolate;
    color: #000;
    font-family: Roboto;
    font-weight: 400;
}

.solid-section .content-cols-wrapper .solid-section-content-wrapper {
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 10px !important;
    padding-right: 0 !important;
}

.solid-section .content-cols-wrapper .solid-section-content-wrapper .solid-section-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    line-height: 1.5;
    margin: 0;
    max-width: none;
    position: relative;

    &::before {
        content: ' ';
        display: table;
        box-sizing: border-box;
    }

    &::after {
        clear: both;
        content: ' ';
        display: table;
        box-sizing: border-box;
    }
}

.solid-section .content-cols-wrapper .solid-section-content-wrapper .solid-section-content .content-cols-container {
    display: flex;
    position: relative;
    margin: 0 auto;
    width: 100%;
    line-height: 1.5;
    unicode-bidi: isolate;
    font-family: Roboto;
    font-weight: 400;
}

.solid-section .content-cols-wrapper .solid-section-content-wrapper .solid-section-content .content-cols-container .content-col {
    margin-left: 0 !important;
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-right: 10px !important;
    padding-right: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    font-size: 18px;
    border: 0 solid #333;
    box-sizing: border-box;
    position: relative;
    width: 50%;
    font-family: Roboto;
    font-weight: 400;
    vertical-align: top;
    float: left;
    line-height: 1.5;
    position: relative;
    width: 50%;

    h2, h2 span {
        font-size: 35px;
        color: #000;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
        margin: 0;
    }

    h3, h3 span {
        font-size: 25px;
        color: #000;
        font-family: "PT Sans";
        direction: ltr;
        font-weight: 700;
        font-style: normal;
        margin: 0;
    }
}

@media screen and (min-width: 1200px) {
    .solid-section .content-cols-wrapper,
    .solid-section .content-cols-wrapper .solid-section-content-wrapper .solid-section-content .content-cols-container {
        max-width: 1200px;
    }
}

@media screen and (max-width: 785px) {
    .content-columns-wrapper {
        flex-direction: column;

        div {
            width: 100% !important;
        }
    }

    .content-columns-wrapper .content-column:first-child {
        margin-right: 0 !important;
        margin-bottom: 10px !important;
    }

    .content-columns-wrapper .section-image-container {
        margin: 0 !important;
    }


    .content-cols-container {
        flex-direction: column;

        div {
            width: 100% !important;
        }
    }

    .showcase-photo img:hover {
        transform: scale(1.03);
    }

    .solid-section {
        padding: 30px 15px;
    }

}
